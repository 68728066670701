<template>
  <div class="row h-100 justify-content-between">
    <div class="col-md-4 col-lg-2 col-xl-2 text-white align-items-stretch bg-primary d-none d-sm-block">
      <div class="card-body pt-4 d-none d-sm-block">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-md-8 col-lg-9 col-xl-9">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0 pb-4">
            <div class="row align-items-center">
              <div class="col-3 col-lg-1 col-xl-1">
                  <!-- <img
                  :src="
                    process.logo
                      ? process.logo.uri
                      : 'assets/media/empaville/btn-phase-01.png'
                  "
                  class="w-100 mb-3"
                /> -->
              </div>
              <div class="col-9 col-lg-11 col-xl-11">
                  <h3 class="text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1">
                    {{$t("PROCESSCONTATTI.CONTATTI")}}
                  </h3>
                  <h4 style="line-height: 1.5em;" class="w-75" v-html="process.contact"> </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-none d-sm-block col-1 justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <ProcessMenu :process_id="id"></ProcessMenu>
      </div>
    </div>
  </div>
</template>


<script>
// import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";

export default {
  name: "processContatti",
  components: {
    Phaseline,
    ProcessMenu
  },
  data() {
    return {
      process: [],
      id: this.$route.params.id
    };
  },
  computed: {
    getProcessLogo() {
      return this.process.logo
          ? this.process.logo.uri
          : '/assets/media/bipart/banner.png'
    },

  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.$t("BCPROCESSI.PROCESSO")}
    ]);
  },
  created() {
    // this.process = this.$store.dispatch(GET_PROCESS, this.id);
    this.getProcess();
  },
  methods: {
    getProcess() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("processes-expanded/" + this.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            //console.log(res);
            this.process = res.data;
            // console.log(this.process);
          })
          .catch(() => {
            // .catch(({ response }) => {
            //   console.log(response);
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    }
  }
};
</script>

<style></style>
